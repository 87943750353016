import BuyCrypto from '../../components/Account/BuyCrypto';
import { useEffect, useState } from 'react';
import {
  createOrder,
  getChangellyOffers,
} from '../../api/services/Account.api';
import { IPaymentOffers } from '../../types/common.type';

interface BuyCryptoContainerProps {
  hideHeader?: boolean;
}

const BuyCryptoContainer = ({ hideHeader }: BuyCryptoContainerProps) => {
  const [amount, setAmount] = useState<number | null>(null);
  const [loadingOffers, setLoadingOffers] = useState<boolean>(false);
  const [offers, setOffers] = useState<IPaymentOffers[] | null>(null);
  const [selectedOffer, setSelectedOffer] = useState<IPaymentOffers | null>(
    null
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (amount && amount >= 30) {
        setLoadingOffers(true);
        getChangellyOffers({
          currencyFrom: 'USD',
          currencyTo: 'USDTT',
          amountFrom: String(amount),
        })
          .then((resp: any) => {
            if (resp.data) {
              setOffers(
                resp.data.data.filter((item: any) => !item.errorMessage)
              );
            }
          })
          .finally(() => {
            setLoadingOffers(false);
            setSelectedOffer(null);
          });
      } else {
        setOffers(null);
        setSelectedOffer(null);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [amount]);

  const onConfirm = (): void => {
    setConfirmLoading(true);
    createOrder({
      providerCode: selectedOffer?.providerCode,
      currencyFrom: 'USD',
      currencyTo: 'USDTT',
      amountFrom: String(amount),
    })
      .then((resp: any) => {
        if (resp.data.data) {
          window.open(resp.data.data.redirectUrl, '_blank');
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  return (
    <BuyCrypto
      amount={amount}
      setAmount={setAmount}
      loadingOffers={loadingOffers}
      offers={offers}
      selectedOffer={selectedOffer}
      setSelectedOffer={setSelectedOffer}
      onConfirm={onConfirm}
      confirmLoading={confirmLoading}
      hideHeader={hideHeader}
    />
  );
};

export default BuyCryptoContainer;
